import React from 'react'

// Data
import FundsJSON from '../../../../static/fundos.json'

// Components
import FundSlider, { ICategoria } from '../../../components/FundSlider/MultiCarrosel'

const Fundos = () => {
  return (
    <section className='bg-gray-400 pt-4 pt-lg-5' id='fundosSection'>
      <div className='container'>
        {
          FundsJSON
            .filter((category) => category.fundos.length > 0)
            .map((category) => (
              <div key={category.idCategoria} className='py-4'>
                <h3 className='f-sora fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-400 text-blue-900'>
                  {category.primeiroNome} <span className='text-orange-medium'>{category.segundoNome}</span>
                </h3>
                <FundSlider
                  category={`${category.primeiroNome} ${category.segundoNome}`}
                  funds={category.fundos}
                />
              </div>
            ))
        }
      </div>
    </section>
  )
}

export default Fundos
