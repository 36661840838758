import React, { useEffect, useState } from 'react'
import DefaultCarousel from '../../../components/UI/Carousels/DefaultCarousel'

// Helpers
import convertToSlug from '../../../helpers/convertToSlug'

// Json
import useWidth from '../../../hooks/useWidth'

// Components
import Icon from '../../Icon'
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'

// Style
import { FundSlider, FundSliderGroup, FundSliderItem, ExternalFundSliderItem, FundSliderButton } from './style'

export interface IDoc {
  nome: string;
  url: string;
  data?: string;
}

export interface ICategoria {
  idCategoria: string;
  primeiroNome: string;
  segundoNome: string;
  fundos: IFundo[];
}

export interface ICracteristicas {
  nome: string;
  valor: string;
}

export interface IFundo {
  nome: string;
  descricao: string;
  linkExterno?: string;
  qualidades: string[];
  materialDivulgacao: IDoc;
  objetivo: string;
  caracteristicasGerais: ICracteristicas[];
  aplicacaoMinima: string;
  movimentacaoMinima: string;
  saldoMinimo: string;
  tributacao: string;
  classificacaoAnbima: string;
  publicoAlvo: string;
  taxaAdministracao: string;
  taxaPerformance: string;
  cotacaoAplicacao: string;
  cotacaoResgate: string;
  liquidacaoResgate: string;
  benchmark: string;
  documentacao: IDoc[];
  avisoLegal: string;
  materialDivulgacaoLabel?: string;
};

interface IFundSlider {
  indicatorColor?: string;
  showIndicators?: {
    [param: string]: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
  };
  showArrows?: {
    [param: string]: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
  };
  visibleItems: {
    [param: string]: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  category: string;
  funds: IFundo[];
  activeFund?: IFundo;
}

const WIDTH_MD = 768
const WIDTH_LG = 992
const WIDTH_XL = 1200

const FundSliderComponent: React.FC<IFundSlider> = ({ indicatorColor, showIndicators, showArrows, visibleItems, category, funds, activeFund }: IFundSlider) => {
  const windowWidth = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ itemSlider, setItemSlider ] = useState<IFundo[][]>([])
  const [ breakpoint, setBreakpoint ] = useState<string>('sm')
  const [ activeItem, setActiveItem ] = useState<number>(0)

  function handleSliderChange (item: number) {
    setActiveItem(item)
  }

  useEffect(() => {
    function formatData () {
      let counter = 0
      let group = 0
      const sliderItem = []
      for (let i = 0; i < funds.length; i++) {
        if (counter < visibleItems[breakpoint]) {
          if (sliderItem[group]) {
            sliderItem[group].push(funds[i])
          } else {
            sliderItem.push([ funds[i] ])
          }
        } else {
          counter = 0
          group++
          sliderItem.push([ funds[i] ])
        }
        counter++

        if (activeFund) {
          if (activeFund.nome === funds[i].nome) {
            setActiveItem(group)
          }
        }
      }
      setItemSlider(sliderItem)
    }

    formatData()
  }, [ breakpoint ])

  useEffect(() => {
    if (windowWidth >= WIDTH_XL) {
      setBreakpoint('xl')
    } else if (windowWidth >= WIDTH_LG && windowWidth < WIDTH_XL) {
      setBreakpoint('lg')
    } else if (windowWidth >= WIDTH_MD && windowWidth < WIDTH_LG) {
      setBreakpoint('md')
    } else {
      setBreakpoint('sm')
    }

    if (!activeFund) {
      setActiveItem(0)
    }
  }, [ windowWidth ])

  const renderArrowPrev = (onClickHandler: () => void, hasPrev: boolean) => (
    hasPrev && (
      <FundSliderButton onClick={onClickHandler}>
        <Icon width={24} height={24} directory='v2' icon='navigation/arrow-left' color='orange-medium' />
      </FundSliderButton>
    )
  )

  const renderArrowNext = (onClickHandler: () => void, hasNext: boolean) => (
    hasNext && (
      <FundSliderButton onClick={onClickHandler}>
        <Icon width={24} height={24} directory='v2' icon='navigation/arrow-right' color='orange-medium' />
      </FundSliderButton>
    )
  )

  return (
    <FundSlider indicatorColor={indicatorColor}>
      <DefaultCarousel
        sm={{ items: 1 }}
        md={{ items: 1 }}
        lg={{ items: 1 }}
        xl={{ items: 1 }}
      >
        {
          itemSlider.map((arr: IFundo[], index: number) => (
            <FundSliderGroup key={index}>
              {
                arr.map((item: IFundo) => item.linkExterno ? (
                  <ExternalFundSliderItem
                    href={item.linkExterno}
                    key={item.nome}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        section_name: category,
                        element_name: item.nome,
                        element_action: 'click button',
                        redirect_url: item.linkExterno,
                      })
                    }}
                  >
                    <p className='fs-14 fw-600 text-left mb-0 mr-3'>{item.nome}</p>
                    <Icon width={16} height={16} icon='chevron-right' directory='v2/navigation' color='orange-medium' />
                  </ExternalFundSliderItem>
                ) : (
                  <FundSliderItem
                    active={(activeFund?.nome === item.nome) || undefined}
                    to={'/' + convertToSlug(category, item.nome)}
                    target='_blank'
                    rel='noopener noreferrer'
                    key={item.nome}
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_04',
                        section_name: category,
                        element_name: item.nome,
                        element_action: 'click button',
                        redirect_url: '/' + convertToSlug(category, item.nome),
                      })
                    }}
                  >
                    <p className='fs-14 fw-600 text-left mb-0 mr-3'>{item.nome}</p>
                    <Icon width={16} height={16} icon='chevron-right' directory='v2/navigation' color='orange-medium' />
                  </FundSliderItem>
                ))
              }
            </FundSliderGroup>
          ))
        }
      </DefaultCarousel>
    </FundSlider>
  )
}

FundSliderComponent.defaultProps = {
  showIndicators: {
    sm: true,
    md: false,
    lg: false,
    xl: false,
  },
  showArrows: {
    sm: false,
    md: true,
    lg: true,
    xl: true,
  },
  visibleItems: {
    sm: 2,
    md: 3,
    lg: 4,
    xl: 5,
  },
}

export default FundSliderComponent
