import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import breakpoints from '../../../styles/breakpoints'
import { white, gray, brown, orange } from '../../../styles/colors'

interface IFundSlider {
  indicatorColor?: string;
}

export const FundSlider = styled.div<IFundSlider>`
  padding: 10px 0;

  .carousel {
    .slider-wrapper {
      padding-bottom: 10px;
      
      @media(min-width: ${breakpoints.md}) {
        padding: 10px 0;
      }
    }

    .slide {
      background-color: transparent;
      padding: 10px 0;
    }

    .control-dots {
      bottom: -15px;

      .dot {
        width: 6px;
        height: 6px;
        background: ${(props: IFundSlider) => props.indicatorColor ? props.indicatorColor : orange.medium};
        opacity: 0.35;
        border-radius: 50%;
        box-shadow: none;
        margin: 0 4px;
        transition: all 0.3s ease 0s;
        outline: none;

        &.selected {
          opacity: 1;
        }
      }
    }
  }
`

export const FundSliderGroup = styled.div`
  display: flex;
  align-items: center;
`

interface IFundSliderItem {
  active?: boolean;
}

export const ExternalFundSliderItem = styled.a<IFundSliderItem>`
height: 105px;
  width: 180px;
  margin: 0 5px;
  background: ${white};
  box-shadow: 0px 1px 4px rgba(75, 78, 92, 0.2);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${gray[700]};
  }


  ${(props: IFundSliderItem) => props.active && css`
    background-color: ${brown.base};

    p {
      color: ${white};
    }
    
    svg {
      fill: ${white};
    }
  `}
  
  @media(min-width: ${breakpoints.xl}) {
    margin: 0 10px;
  }
`

export const FundSliderItem = styled(Link)<IFundSliderItem>`
  height: 105px;
  width: 180px;
  margin: 0 5px;
  background: ${white};
  box-shadow: 0px 1px 4px rgba(75, 78, 92, 0.2);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${gray[700]};
  }


  ${(props: IFundSliderItem) => props.active && css`
    background-color: ${brown.base};

    p {
      color: ${white};
    }
    
    svg {
      fill: ${white};
    }
  `}
  
  @media(min-width: ${breakpoints.xl}) {
    margin: 0 10px;
  }
`

export const FundSliderButton = styled.button`
  background: none;
  border: none;
  border-radius: 8px;
  outline: none;

  &:active {
    outline: none;
  }
`
